<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    @click:outside="clickOutside"
  >
    <template v-slot:activator="{ on, attrs }">
      <p id="payreqItemForm" v-bind="attrs" v-on="on" @click="openItemForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto">DETAIL BARANG</span>
      </v-card-title>
      <v-form>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kode Barang
                </p>

                <v-text-field
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="9" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Nama Barang
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" md="3" class="d-flex flex-column"></v-col> -->
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty Request
                </p>

                <v-text-field
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty Dikembalikan
                </p>

                <v-text-field
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty Selesai
                </p>

                <v-text-field
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Satuan
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Keterangan
                </p>
                <v-textarea dense outlined rows="3" style="font-size: 12px" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              @click="saveForm"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              @click="closeDialog"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'payreqItemForm',
  props: [],
  data() {
    return {
      dialog: false,
      form: null
    }
  },
  computed: {
    // headers() {
    //   if (this.type === 1) {
    //     const headerTypeOne = []
    //     const keys = Object.keys(this.importData[0])
    //     console.log(keys)
    //     for (let i = 0; i < keys.length; i++) {
    //       if (
    //         keys[i] === 'body_temperature' ||
    //         keys[i] === 'device_code' ||
    //         keys[i] === 'employee_code' ||
    //         keys[i] === 'employee_name' ||
    //         keys[i] === 'attendance_time'
    //       ) {
    //         headerTypeOne.push({
    //           text: keys[i],
    //           value: keys[i],
    //           align: 'left',
    //           sortable: false
    //         })
    //       }
    //     }
    //     return headerTypeOne
    //   } else {
    //     return [
    //       {
    //         text: 'NIK',
    //         value: 'nik',
    //         align: 'left',
    //         sortable: false
    //       },
    //       {
    //         text: 'Time',
    //         value: 'attendance_time',
    //         align: 'left',
    //         sortable: false
    //       },
    //       {
    //         text: 'Message',
    //         value: 'msg',
    //         align: 'left',
    //         sortable: false
    //       }
    //     ]
    //   }
    // }
  },
  mounted() {},
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    openItemForm() {
      console.log('hallo world')
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      //   this.closeDialog()
    },
    closeDialog() {
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    saveForm() {
      setTimeout(() => {
        this.dialog = false
      }, 200)
    }
  }
}
</script>
